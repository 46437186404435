




















































import { Component, Vue } from 'vue-property-decorator';
import AccessMoreStrategiesStrayWithCurrentStrategyViewModel from
  '@/vue-app/view-models/allianz-dashboard/access-more-strategies/access-more-strategies-stay-with-current-strategy-view-model';

@Component({ name: 'AccessMoreStrategiesStayWithCurrentStrategy' })
export default class AccessMoreStrategiesStayWithCurrentStrategy extends Vue {
  stay_with_current_strategy_view_model = Vue.observable(
    new AccessMoreStrategiesStrayWithCurrentStrategyViewModel(this),
  );
}
