import TYPES from '@/types';

import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AccessMoreStrategiesStayWithCurrentStrategyViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.stay-with-current-strategy';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  accept = () => {
    this.view.$emit('close');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  openDefineGoalsFlow = () => {
    this.view.$emit('openDefineGoalsFlow');
  }
}
